import {
    USER,
    USER_SET_SUCCESS
} from "../actionType";

const INITIAL_STATE=null;

const user = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case USER:

            return {
                ...state,
            };
        case USER_SET_SUCCESS:
            return {
                ...action.payload
            };
        default:
            return state
    }
};
export default user;