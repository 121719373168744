import React, { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import LoaderPost from "../../Components/Loader";
//import Sidebar from "./Components/Sidebar/Sidebar";

const Loader = () => (
  <></>
)
const Guest = (props) => {
  const {
    route,
  } = props;

  return (
    <div>
      
      {/* <Sidebar props={props} /> */}
      <Suspense
        fallback={<Loader />}
      >

        <LoaderPost identifier={'1'} />
        {renderRoutes(route.routes)}

      </Suspense >
    </div>
  );
};

Guest.propTypes = {
  route: PropTypes.object,
};

export default Guest;
