import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import * as constants from './Utils/constants';
import { BASE_URL } from './Utils/url';

const languages = {
    "en": "en",
    "en-us": "en",
    "no": "no",
    "hi": "hi",
};

//const translations = JSON.parse(window.sessionStorage.getItem(constants.LOCALSTORAGE.translations)) || null;

const browser_lang = navigator.language || navigator.userLanguage;

const get_lang = languages[browser_lang];

const user_lang = window.sessionStorage.getItem(constants.LOCALSTORAGE.lang) || get_lang || "en";

const translation_path = "default" //window.sessionStorage.getItem("tenant")=="College"?"college":"default";
const translationsData = `${BASE_URL.CDN}/tenants/${window.sessionStorage.getItem('tenant') || 'MazoolaPlus'}/assets/translations/${user_lang}.json`;

i18n
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        
        lng: user_lang,
        fallbackLng: 'en',
        whitelist: ['en', 'fr', 'no', 'es'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        //resources: translations,
        backend: {
            //loadPath: `/locales/${translation_path}/${user_lang}/translation.json`,
            // loadPath: `/assets/translations/${user_lang}.json`,
            loadPath : translationsData
        },
    });

    
//    console.log(college);

//    i18n.addResourceBundle(user_lang,"translation",college,true,true)

export default i18n;