import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "./Store/history/history";
import defaultRoutes from "./Routes/Routes";
import Routes_3001 from "./Routes/Routes_3001";
import Routes_3002 from "./Routes/Routes_3002";
// Internal imports
//import LoginRoute from "./Routes/LoginRoute";
import PrivateRoute from './Routes/PrivateRoutes';
import ToastMessage from './Components/ToastMessage';
import { showToastMessage, resetToastMessage, setStartLoader, setStopLoader } from './Store/action/loader';
import { getTheme, setTheme } from './Store/action/theme';
import { getUser, setUser } from './Store/action/user';

//import UnAuth from "./Error";
//import Loader from "./Components/Loader";
//import { isLogin } from "./Utils/token";
import { useEffect } from "react";

import { _theme, _user, _rawFetch, _list } from "./Store/action/crud";
import * as constants from './Utils/constants';
//import THEME_CALL from "./Components/Themes/Theme";
import { isLogin, setToken } from "./Utils/token";
import { getTenantName } from "./Utils";
import { BASE_URL } from "./Utils/url";


let routes = defaultRoutes;
let tenantsName = constants.ENTITY_BRAND.MAZOOLA;

// if (window.location.port === '3001' || window.location.host === 'signin.mazoola.co' || window.location.host === 'regostage.regopayments.com') {
//   routes = Routes_3001; // login
// } else if (window.location.port === '3002' || window.location.host === 'register.mazoola.co' || window.location.host === 'mazoolastage.regopayments.com') {
//   routes = Routes_3002; // signup
// }

tenantsName = getTenantName();

// console.error = function () { }
// console.warn = function () { }

function App(props) {
  const {
    toast,
  } = props
  const resolvePrivateRoutes = (routes) => {

    if (routes && Array.isArray(routes)) {
      return routes.map((route) => {

        if (route.isPrivate) {
          route.render = (props) => (
            <PrivateRoute component={route.component} {...props} />
          );
        }
        if (route.isAuth) {
          route.render = (props) => (
            <PrivateRoute component={route.component} {...props} />
          );

        }
        if (route.routes) {
          resolvePrivateRoutes(route.routes);
        }
        return route;
      });
    }
  };

  useEffect(() => {
    const tenant = localStorage.getItem("banno");
    if(tenant === "1"){
      setToken(localStorage.getItem("access_token"))
    }
    fetchTheme();
    if (isLogin()) {
      fetchUser();
    }

  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  const fetchTheme = () => {

    const contrast = sessionStorage.getItem('contrast');
    //console.log('contrast',contrast)
    if(contrast==="false"){
      sessionStorage.removeItem('contrast')
    }

    props._rawFetch(`${BASE_URL.CDN}/lists/branding.json`, null, response => {

    }, err => {
    })

  }

  const fetchUser = () => {
    props._user(constants.V6USERS + "?module=global", "", (res) => {

    })
  }

  return (
    <>
      {/* {!isLogin()?
      <Loader identifier='0' />
      :<></>} */}
      <Router history={history} basename={process.env.PUBLIC_URL}>
        <ToastMessage toastData={toast && toast} />

        {renderRoutes(resolvePrivateRoutes(routes))}

      </Router>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    toast: state.loader.toast,
    loaderStatus: state.loader.loaderStatus,
    tenantsName: tenantsName
    //currentEntity:currentEntity
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showToastMessage,
    resetToastMessage,
    setStartLoader,
    setStopLoader,
    getTheme,
    setTheme,
    setUser,
    getUser,
    _theme,
    _user,
    _rawFetch,
    _list
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
