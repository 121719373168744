//import * as constants from './Utils/constants';

//import masterTranslation from './master.translation.json';


const delay = ms => new Promise(res => setTimeout(res, ms));

const beforeInit = async (translations, lang) => {
    

    await delay(1000);
    
    if (!lang || lang === "null") {
    }
    await delay(1000);
};

export default beforeInit;
