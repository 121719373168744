import React from 'react'

const Investment = props => {
    return (

        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.2 38.32">

            <g id="Isolation_Mode" data-name="Isolation Mode">
                <path fill={props.color} d="m9.39,27.28H2.69C1.21,27.28,0,28.48,0,29.96v5.67c0,1.48,1.2,2.68,2.68,2.68h6.7c1.48,0,2.68-1.2,2.68-2.68v-5.67c0-1.48-1.2-2.68-2.68-2.68Zm-.07,8.29l-6.57.06-.07-5.61,6.64-.07v5.61h0Zm14.13-11.73h-6.7c-1.48,0-2.68,1.2-2.68,2.68v9.12c0,1.48,1.2,2.68,2.68,2.68h6.7c1.48,0,2.68-1.2,2.68-2.68v-9.12c0-1.48-1.2-2.68-2.68-2.68Zm-.07,11.74l-6.57.06-.07-9.05,6.64-.07v9.05h0Zm14.13-17.74h-6.7c-1.48,0-2.68,1.2-2.68,2.68v15.11c0,1.48,1.2,2.68,2.68,2.68h6.7c1.48,0,2.68-1.2,2.68-2.68v-15.11c0-1.48-1.2-2.68-2.68-2.68Zm-.07,17.73l-6.57.06-.07-15.05,6.64-.07v15.05h0ZM1.38,24.03c9.22,0,27.53-3.7,35.73-17.16l.36,1.91c.12.66.7,1.12,1.35,1.12.08,0,.17,0,.26-.02.75-.14,1.24-.86,1.1-1.61l-1.1-5.85h0c-.02-.09-.05-.18-.08-.27,0-.03-.03-.05-.04-.08-.03-.06-.06-.12-.1-.17-.02-.03-.04-.06-.07-.08-.04-.05-.08-.09-.12-.13-.03-.03-.06-.05-.09-.07-.05-.04-.09-.07-.15-.1-.02-.01-.03-.03-.05-.04-.02,0-.04-.01-.06-.02-.02-.01-.04-.03-.07-.04-.03-.01-.06-.02-.09-.03-.04-.01-.08-.03-.12-.04-.06-.01-.12-.02-.18-.03h-.1c-.07,0-.15,0-.22.01h-.07c-.1.02-.19.04-.28.08h0l-6.01,2.53c-.7.29-1.03,1.1-.73,1.8.29.7,1.1,1.03,1.8.73l2.56-1.08C25.39,21.08,1.63,21.28,1.38,21.28c-.76,0-1.38.62-1.38,1.38s.62,1.38,1.38,1.38h0Zm6.29-13.74c0-.41-.34-.75-.75-.75s-.75.34-.75.75c0,1.12.87,2.06,2.04,2.35v.03c0,.41.34.75.75.75s.75-.34.75-.75v-.03c1.18-.29,2.04-1.23,2.04-2.35s-.87-2.06-2.04-2.35v-1.8c.32.18.54.44.54.75,0,.41.34.75.75.75s.75-.34.75-.75c0-1.12-.87-2.06-2.04-2.35v-.03c0-.41-.34-.75-.75-.75s-.75.34-.75.75v.03c-1.17.29-2.04,1.23-2.04,2.35s.87,2.06,2.04,2.35v1.8c-.32-.18-.54-.44-.54-.75Zm2.59,0c0,.31-.22.58-.54.75v-1.5c.32.18.54.44.54.75Zm-2.59-3.41c0-.31.22-.57.54-.75v1.5c-.32-.18-.54-.44-.54-.75Zm1.29,10.29c4.73,0,8.59-3.85,8.59-8.58S13.7,0,8.96,0,.38,3.85.38,8.59s3.85,8.58,8.58,8.58Zm0-15.17c3.63,0,6.59,2.95,6.59,6.59s-2.95,6.58-6.59,6.58-6.58-2.95-6.58-6.58,2.95-6.59,6.58-6.59Z" />
            </g>
        </svg>
    )
}

export default Investment;