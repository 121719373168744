import React from "react";

const Watchlist = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3904 2.41441C11.7397 1.3396 13.2603 1.33961 13.6096 2.41441L15.3815 7.86731C15.5377 8.34796 15.9856 8.67339 16.491 8.67341L22.2246 8.67363C23.3548 8.67367 23.8246 10.1198 22.9104 10.7841L18.2719 14.1544C17.8631 14.4515 17.692 14.978 17.8481 15.4587L19.6197 20.9117C19.9689 21.9866 18.7387 22.8803 17.8244 22.2161L13.1857 18.8462C12.7768 18.5491 12.2232 18.5491 11.8143 18.8462L7.17559 22.2161C6.26127 22.8803 5.03111 21.9866 5.3803 20.9117L7.15187 15.4587C7.30803 14.978 7.13694 14.4515 6.72807 14.1544L2.08964 10.7841C1.17536 10.1198 1.64524 8.67367 2.77538 8.67363L8.50896 8.67341C9.01436 8.67339 9.46227 8.34796 9.61847 7.8673L11.3904 2.41441Z"
        stroke={iconColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Watchlist;
