import {
    USER,
    USER_SET_SUCCESS
} from "../../actionType";

export const getUser = _payload => {
    return { type: USER, payload: _payload }
}

export const setUser = _payload => {
    return { type: USER_SET_SUCCESS, payload: _payload }
}
