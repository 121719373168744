import React from 'react'

const Chores = props => {
    const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

    return (

        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="none" stroke={iconColor} strokeWidth="2px" d="M1,9.58A22,22,0,0,1,1.36,5,4.75,4.75,0,0,1,2.54,2.54,4.75,4.75,0,0,1,5,1.36,22,22,0,0,1,9.58,1h4.84A22,22,0,0,1,19,1.36a4.75,4.75,0,0,1,2.43,1.18A4.75,4.75,0,0,1,22.64,5,22,22,0,0,1,23,9.58v4.84A22,22,0,0,1,22.64,19a4.75,4.75,0,0,1-1.18,2.43A4.75,4.75,0,0,1,19,22.64a22,22,0,0,1-4.61.36H9.58A22,22,0,0,1,5,22.64a4.75,4.75,0,0,1-2.43-1.18A4.75,4.75,0,0,1,1.36,19,22,22,0,0,1,1,14.42Z" />
            <path fill="none" stroke={iconColor} strokeWidth="2px" strokeLinecap="round" strokeLinejoin="round" d="M17.38,9l-6.9,6.9L7,12.4" />
        </svg>
    )
}

export default Chores;
