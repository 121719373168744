export const API_INVOCATION = "API_INVOCATION";

export const LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD";
export const LOGIN_WITH_PASSWORD_SUCCESS="LOGIN_WITH_PASSWORD_SUCCESS";
export const LOGIN_WITH_PASSWORD_ERROR="LOGIN_WITH_PASSWORD_ERROR";

export const SHOW_TOAST_MESSAGE="SHOW_TOAST_MESSAGE";
export const RESET_SHOW_TOAST_MESSAGE="RESET_SHOW_TOAST_MESSAGE";
export const START_LOADER="START_LOADER";
export const STOP_LOADER="STOP_LOADER";

export const GET="GET";
export const GET_SUCCESS="GET_SUCCESS";
export const GET_ERROR="GET_ERROR";

export const POST="POST";
export const POST_SUCCESS="POST_SUCCESS";
export const POST_ERROR="POST_ERROR";

export const PATCH="PATCH";
export const PATCH_SUCCESS="POST_SUCCESS";
export const PATCH_ERROR="POST_ERROR";

export const DELETE="DELETE";
export const DELETE_SUCCESS="DELETE_SUCCESS";
export const DELETE_ERROR="DELETE_ERROR";

export const THEME="THEME";
export const THEME_SET="THEME_SET";
export const THEME_SET_SUCCESS="THEME_SET_SUCCESS";
export const THEME_SET_TRIGGER="THEME_SET_TRIGGER";

export const FEATURE="FEATURE";
export const FEATURE_SET="FEATURE_SET";
export const FEATURE_SET_SUCCESS="FEATURE_SET_SUCCESS";

export const FEATURE_WARD="FEATURE_WARD";
export const FEATURE_WARD_SET="FEATURE_WARD_SET";
export const FEATURE_WARD_SET_SUCCESS="FEATURE_WARD_SET_SUCCESS";

export const USER="USER";
export const USER_SET="USER_SET";
export const USER_SET_SUCCESS="USER_SET_SUCCESS";

export const INVESTMENTSLIST="INVESTMENTSLIST";
export const INVESTMENTSLIST_SET="INVESTMENTSLIST_SET";
export const INVESTMENTSLIST_SET_SUCCESS="INVESTMENTSLIST_SET_SUCCESS";











