import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import App from './App';
import { Provider as StoreProvider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import beforeInit from './beforeInit';
import Store from "./Store/index";
import pkg from '../package.json'
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import './Firefox.scss';
import './investment.scss';
import './childModule.scss';
import './tenant.scss';
import '../node_modules/jquery/dist/jquery.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/popper.js/dist/popper.js';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './i18n';
import '../src/fonts/GothamLight.ttf';
import '../src/fonts/GothamMedium.ttf';
import '../src/fonts/GothamBook.ttf';
import '../src/fonts/GothamBold.ttf';
import * as constants from './Utils/constants';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from './Utils/url';

const ENV_PROD = "prod";
const translations = sessionStorage.getItem(constants.LOCALSTORAGE.translations);
const lang = sessionStorage.getItem(constants.LOCALSTORAGE.lang);
const URL_SPLIT = (BASE_URL.API).split("-");
const ENV = (URL_SPLIT.length > 1) ? URL_SPLIT[1] : ENV_PROD;

beforeInit(translations, lang);

//if (translations) {

if (lang === "null") {
  
}

// } else {

// }


// Sentry.init({
//   dsn: "https://40f7204f29b64b0aa77fb8dfb5c8a7ae@o4505392482353152.ingest.sentry.io/4505403841839104",
//   release: "react-web-app@" + pkg.version,
//   debug: (ENV === ENV_PROD) ? false : false,
//   environment: ENV,
//   attachStacktrace: true,
//   autoSessionTracking: true,
//   sendDefaultPii: false,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", "https://app.mazoola.co", "https://regostage.regopayments.com", "https://mazoolastage.regopayments.com"],
//       // See docs for support of different versions of variation of react router
//       // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         React.useEffect,
//         useLocation,
//         //   useNavigationType,
//         //   createRoutesFromChildren,
//         //   matchRoutes
//       ),
//     }),
//     new Sentry.Replay()
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   tracesSampleRate: 1.0,

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });


ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={Store}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();