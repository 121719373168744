import React from "react";

const SuperpowerBulb = (props) => {
  let iconColor = props.state?.theme?.values?.tertiaryGrayColor ? props.state.theme.values.tertiaryGrayColor : '#a8aeaf';

  return (
    
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.4 40.6">
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <path fill={iconColor} d="m21.3,37.4h-12.4c-.9,0-1.6.7-1.6,1.6s.7,1.6,1.6,1.6h12.5c.9,0,1.6-.7,1.6-1.6s-.8-1.6-1.7-1.6Z"/>
      <path fill={iconColor} d="m15.1,0C6.8,0,0,6.8,0,15.1c0,6.2,3.2,9.7,6.2,12.1v5.5c0,.9.7,1.6,1.6,1.6h14.8c.9,0,1.6-.7,1.6-1.6v-5.5c2.8-2.3,6.2-5.9,6.2-12.1-.1-8.3-6.9-15.1-15.3-15.1Zm6.5,25.1c-.3.3-.6.8-.6,1.2v4.7h-11.7v-4.7c0-.5-.2-.9-.7-1.2-2.8-2.1-5.5-4.8-5.5-10,.1-6.7,5.4-12,12.1-12s12,5.4,12,12c-.1,5.2-3,8.1-5.6,10Z"/>
    </g>
  </g>
</svg>
  );
};

export default SuperpowerBulb;
