import React from "react";

const Settings = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1905 12.99C22.1905 12.37 22.1205 11.77 22.0005 11.19C21.9605 10.98 22.0505 10.77 22.2305 10.66L23.8705 9.71C24.3005 9.47 24.5005 8.95 24.3205 8.5C23.9005 7.47 23.3505 6.52 22.6805 5.66C22.3705 5.26 21.8105 5.13 21.3705 5.39L19.7405 6.33C19.5505 6.44 19.3205 6.41 19.1605 6.27C18.2605 5.47 17.2005 4.85 16.0405 4.47C15.8405 4.4 15.7005 4.22 15.7005 4V2.11C15.7005 1.6 15.3105 1.18 14.8105 1.11C14.2805 1.04 13.7505 1 13.2005 1C12.6505 1 12.1205 1.04 11.5905 1.11C11.0805 1.18 10.7005 1.6 10.7005 2.11V4C10.7005 4.22 10.5605 4.4 10.3605 4.47C9.19048 4.86 8.14048 5.48 7.24048 6.27C7.06048 6.4 6.83048 6.43 6.65048 6.33L5.02048 5.38C4.58048 5.12 4.02048 5.25 3.71048 5.65C3.04048 6.52 2.48048 7.47 2.07048 8.5C1.88048 8.95 2.09048 9.47 2.51048 9.71L4.15048 10.66C4.34048 10.77 4.43048 10.98 4.38048 11.19C4.26048 11.77 4.19048 12.37 4.19048 12.99C4.19048 13.61 4.26048 14.21 4.38048 14.79C4.42048 15 4.33048 15.21 4.15048 15.32L2.51048 16.27C2.09048 16.51 1.89048 17.03 2.07048 17.48C2.49048 18.51 3.04048 19.46 3.71048 20.32C4.02048 20.72 4.58048 20.85 5.02048 20.59L6.65048 19.65C6.84048 19.54 7.07048 19.57 7.23048 19.71C8.13048 20.51 9.19048 21.13 10.3505 21.51C10.5505 21.58 10.6905 21.76 10.6905 21.98V23.87C10.6905 24.38 11.0805 24.8 11.5805 24.87C12.1105 24.94 12.6405 24.98 13.1905 24.98C13.7405 24.98 14.2705 24.94 14.8005 24.87C15.3105 24.8 15.6905 24.38 15.6905 23.87V21.98C15.6905 21.76 15.8305 21.58 16.0305 21.51C17.2005 21.12 18.2505 20.5 19.1505 19.71C19.3105 19.57 19.5405 19.54 19.7305 19.65L21.3605 20.59C21.8005 20.85 22.3605 20.72 22.6705 20.32C23.3405 19.46 23.8905 18.5 24.3105 17.48C24.4905 17.03 24.2905 16.51 23.8705 16.27L22.2305 15.32C22.0405 15.21 21.9505 15 22.0005 14.79C22.1205 14.21 22.1905 13.61 22.1905 12.99ZM14.3705 16.82C11.7505 17.61 9.16048 15.65 9.19048 12.92C9.21048 11.22 10.3805 9.66 12.0105 9.17C14.6305 8.38 17.2205 10.34 17.1905 13.07C17.1705 14.77 16.0005 16.33 14.3705 16.82Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Settings;
