import {
    THEME,
    THEME_SET_SUCCESS
} from "../../actionType";

export const getTheme = _payload => {
    return { type: THEME, payload: _payload }
}

export const setTheme = _payload => {
    return { type: THEME_SET_SUCCESS, payload: _payload }
}
