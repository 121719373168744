import React from 'react'

const Shopping = props => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      width="29"
      height="27"
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2002 22.0926C11.2002 24.7957 11.6215 25.375 13.5874 25.375H24.813C26.7789 25.375 27.2002 24.7957 27.2002 22.0926V17.6574C27.2002 14.9543 26.7789 14.375 24.813 14.375H13.5874C11.6215 14.375 11.2002 14.9543 11.2002 17.6574V22.0926Z"
        stroke={iconColor} 
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2002 9.875C22.1016 7.01359 21.2858 6.375 18.2512 6.375H5.15994C1.89909 6.375 1.2002 7.11235 1.2002 10.5526V16.1974C1.2002 19.6377 1.89909 20.375 5.15994 20.375H7.28224"
        stroke={iconColor} 
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.2002 10.875C8.2002 9.375 8.2002 6.37502 8.2002 4.37502C8.2002 -0.125011 15.2002 -0.125 15.2002 4.37502C15.2002 6.37502 15.2002 9.375 15.2002 10.875"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7002 15.375C15.7002 20.875 22.7002 20.875 22.7002 15.375"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Shopping;
