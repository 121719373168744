import React from 'react'

const Education = props => {
    const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

    return (
        <svg
            width="36"
            height="25"
            viewBox="0 0 36 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.2618 23.7083C23.3713 23.7083 27.5134 21.0724 27.5134 17.821C27.5134 14.5695 23.3713 11.9336 18.2618 11.9336C13.1523 11.9336 9.01025 14.5695 9.01025 17.821C9.01025 21.0724 13.1523 23.7083 18.2618 23.7083Z"
                stroke={iconColor}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path d="M9.01025 17.8219V8.57031H27.5134V17.8219" />
            <path
                d="M9.01025 17.8219V8.57031H27.5134V17.8219"
                stroke={iconColor}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M34.4605 7.64911C34.8761 7.81801 34.8762 8.40653 34.4606 8.57551L18.2863 15.1515C18.1656 15.2006 18.0304 15.2006 17.9097 15.1515L1.73931 8.57549C1.32374 8.40649 1.3238 7.81805 1.73939 7.64913L17.9098 1.07652C18.0305 1.02745 18.1655 1.02745 18.2863 1.0765L34.4605 7.64911Z"
                fill="white"
                stroke={iconColor}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M32.5691 15.8018C32.7471 15.4122 33.3006 15.4122 33.4786 15.8018L34.4356 17.8959C34.496 18.0279 34.4959 18.1797 34.4355 18.3117L33.4785 20.4032C33.3004 20.7924 32.7473 20.7924 32.5692 20.4032L31.6122 18.3117C31.5518 18.1797 31.5518 18.0279 31.6121 17.8959L32.5691 15.8018Z"
                fill={iconColor}
                stroke={iconColor}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M33.0229 9.5V15.2287"
                stroke={iconColor}
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </svg>
    );
}

export default Education;
