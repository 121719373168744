import React from 'react'

const QrCode = props => {
    const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

    return (

        <svg width="40" height="25" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
            <g>
                <path fill={iconColor} d="M7.6,37.3c-2.5,0-4.6-2.1-4.7-4.6v-7.4c-0.1-0.8-0.7-1.4-1.6-1.4C0.6,24,0.1,24.5,0,25.2v7.4
			c0.1,4.1,3.5,7.4,7.6,7.4h7.3c1.1,0,1.7-0.5,1.7-1.4c0-0.9-0.7-1.4-1.7-1.4C14.1,37.3,7.6,37.3,7.6,37.3z"/>
                <path fill={iconColor} d="M1.6,16.3c0.8,0,1.5-0.6,1.5-1.5l0,0V7.6C3.1,5,5.1,3,7.6,3H15c0.8-0.1,1.4-0.7,1.4-1.6
			c-0.1-0.7-0.6-1.3-1.4-1.4H7.6c-4.1,0-7.4,3.3-7.4,7.4l0,0v7.3C0.1,15.6,0.7,16.2,1.6,16.3C1.5,16.2,1.5,16.2,1.6,16.3z"/>
                <path fill={iconColor} d="M38.5,23.8c-0.8,0-1.5,0.6-1.5,1.5l0,0v7.3c0,2.5-2.1,4.6-4.6,4.6h-7.3c-0.8-0.1-1.5,0.5-1.6,1.4
			c-0.1,0.8,0.5,1.5,1.4,1.6c0.1,0,0.2,0,0.2,0h7.3c4.1,0,7.4-3.3,7.5-7.4v-7.4C39.9,24.5,39.3,23.8,38.5,23.8z"/>
                <path fill={iconColor} d="M25.1,2.9h7.3c2.5,0,4.6,2,4.6,4.6v7.3c0.1,0.8,0.7,1.4,1.6,1.4c0.7-0.1,1.3-0.6,1.4-1.4V7.4
			c0-4.1-3.4-7.4-7.5-7.4h-7.3c-0.8,0-1.5,0.6-1.6,1.4c-0.1,0.7,0.5,1.5,1.4,1.6C24.9,2.9,25,2.9,25.1,2.9L25.1,2.9z"/>
                <path fill={iconColor} d="M32.2,32.5H7.8v-3.9h5.7v-2.5H7.8v-7.9h3.9v4.3h2v-4.3h3.7v10.4h5.7v-6.1h-3.2v-4.3h7.9V15h-7.9V7.6h3.7
			v3.5h8.6v11.4h-5.7v6.1h5.7V32.5z M17.4,7.6H7.8v8.6h3.9v-4.9h5.7V7.6z M17.4,13h-3.7v3.2h3.7V13z M27.9,27.5h4.3v-3.7h-4.3V27.5z"
                />
            </g>
        </svg>


    )
}

export default QrCode;
