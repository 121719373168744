import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import AuthenticatedLayout from './AuthenticatedLayout';
import { _list,_feature,_investment_account,_post,_featureWard } from "../../Store/action/crud";

function mapStateToProps(state, props) {

    return {
        showLogin : true,
        state:{...state}
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _list,
        _feature,
        _investment_account,
        _post,
        _featureWard
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticatedLayout);

