import React from 'react'

const InvestmentDashboard = props => {
    let iconColor = props.state?.theme?.values?.tertiaryGrayColor;
    return (

        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3333 1.56445C5.08339 2.1448 1 6.59572 1 12.0004C1 17.7994 5.70101 22.5004 11.5 22.5004C16.9046 22.5004 21.3556 18.417 21.9359 13.167H10.3333V1.56445Z" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.4025 8.50037H15V2.09805C17.9834 3.15252 20.3479 5.51702 21.4025 8.50037Z" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default InvestmentDashboard;
