
import { BASE_URL } from "../../Utils/url";

let WHITELABELING = {

    tenantId: 0,
    shortName: "",
    longName: "",
    showHeader: false,
    logo : "",
    images: {
        passwordCheckmark: `${BASE_URL.CDN}/tenants/Mazoola/web/public/assets/images/RegexCheckbox.svg`,
    }
}


export default WHITELABELING;