import React from "react";

const Research = (props) => {
  let iconColor = props.state?.theme?.values?.tertiaryGrayColor;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 22.5L15.5 15.5M17.8333 9.66667C17.8333 14.177 14.177 17.8333 9.66667 17.8333C5.15634 17.8333 1.5 14.177 1.5 9.66667C1.5 5.15634 5.15634 1.5 9.66667 1.5C14.177 1.5 17.8333 5.15634 17.8333 9.66667Z"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Research;
