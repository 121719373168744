import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CreditCard from "./icons/CreditCard";
import Education from "./icons/Education";
import Shopping from "./icons/Shopping";
import Logout from "./icons/Logout";
import Chores from './icons/Chores'
import QrCode from './icons/QrCode'
import EditProfileIcn from "../../../Authenticated/Components/Sidebar/icons/EditProfileIcn";
import Wallet from "../../../Authenticated/Components/Sidebar/icons/Wallet";
import { isPermission } from "../../../../Utils";
import { SESSSION_OUT } from "../../../../Utils/constants";

const Sidebar = ( props) => {

  const { t } = useTranslation();
  const location = useLocation();
  const [themeLoaded,setthemeLoaded] = useState(false);
  const isBank = !props.showHeader || sessionStorage.getItem("__bank");

  useEffect(()=>{
    if(props && props.state && props.state.theme.values){
      setthemeLoaded(true);
    }
  },[props && props.state])
  
  const logout = () => {
    document.querySelectorAll(`body`).forEach(e => e.classList.remove(`child-module`));
    document.querySelectorAll(`body`).forEach(e => e.classList.remove(`practice-mode`));
    SESSSION_OUT(props);
  };

  const toggleSidebar = () => {
    document.querySelector('.child-left-sidebar').classList.toggle('active');
  }

  return (
    <div className="child-left-sidebar">
      <ul>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/dashboard" ? 'active' : ''}`}>
          <Link to="/child/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <Wallet {...props}/>
              </span>
            </span>
            <span>{t('home')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/chores" ? 'active' : ''}`}>
          <Link to="/child/chores">
            <span className="menu-icon">
              <span className="svgIcon">
                <Chores {...props}/>
              </span>
            </span>
            <span>{t('chores_n_goals')}</span>
          </Link>
        </li>

        <li onClick={toggleSidebar} className={`${location.pathname === "/child/education" ? 'active' : ''}`}>
          <Link to="/child/education">
            <span className="menu-icon">
              <span className="svgIcon">
                <Education {...props}/>
              </span>
            </span>
            <span>{t('education')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/shopping" ? 'active' : ''}`}>
          <Link to="/child/shopping">
            <span className="menu-icon">
              <span className="svgIcon">
                <Shopping {...props}/>
              </span>
            </span>
            <span>{t('shopping')}</span>
          </Link>
        </li>

        <li onClick={toggleSidebar} className={`${location.pathname === "/child/profile" ? 'active' : ''}`}>
          <Link to="/child/profile">
            <span className="menu-icon">
              <span className="svgIcon">
                <EditProfileIcn {...props}/>
              </span>
            </span>
            <span>{t('profile')}</span>
          </Link>
        </li>

        {themeLoaded && isPermission(props, `DirectPay`) === true && sessionStorage.getItem('showMazoolaPay') &&
          <li onClick={toggleSidebar} className={`qr-code-menu ${location.pathname === "/child/pay" ? 'active' : ''}`}>
            <Link to="/child/pay">
              <span className="menu-icon">
                <span className="svgIcon">
                  <QrCode {...props}/>
                </span>
              </span>
              <span>{t('pay')}</span>
            </Link>
          </li>
        }

        {!isBank ?
          <li onClick={toggleSidebar} className={`${location.pathname === "/child/logout" ? 'active' : ''}`}>
            <a href="#logout" onClick={logout}>
              <span className="menu-icon">
                <span className="svgIcon">
                  <Logout {...props}/>
                </span>
              </span>
              <span>{t('sign_out')}</span>
            </a>
          </li>
          : null}
      </ul>
    </div>
  );
};

export default Sidebar;
