import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import AuthenticatedLayout from './AuthenticatedLayout';
import { _list,_feature,_user,_investment_account,_post } from "../../Store/action/crud";

function mapStateToProps(state, props) {
    return {
        showLogin : true,
        state:{...state}
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _list,
        _feature,
        _user,
        _post,
        _investment_account
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticatedLayout);

