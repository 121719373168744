import {
    INVESTMENTSLIST,
    INVESTMENTSLIST_SET_SUCCESS
} from "../actionType";

const INITIAL_STATE = [];

const investmentaccount = (state = INITIAL_STATE, action) => {
    // console.log(action)
    // console.log(state)
    switch (action.type) {
        case INVESTMENTSLIST:

            return {
                ...state,
            };
        case INVESTMENTSLIST_SET_SUCCESS:

        if(action.payload && action.payload.length){
            return [
                ...action.payload
            ];
        }
        default:
            return state
    }
};
export default investmentaccount;