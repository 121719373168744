import { 
    GET,
    POST,
    DELETE,
    PATCH,
    THEME_SET,
    API_INVOCATION, 
    USER_SET,
    FEATURE_SET,
    FEATURE_WARD_SET,
    INVESTMENTSLIST_SET} from "../../actionType";
import * as constdata from '../../../Utils/constants';
import * as consturl from '../../../Utils/url';
import * as token from '../../../Utils/token';


export const _list = (endpoint,_payload, resolve, reject,isLoader=true) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: GET,
        isLoader,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _feature = (endpoint,_payload, resolve, reject,isLoader=true) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: FEATURE_SET,
        isLoader,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _featureWard = (endpoint,_payload, resolve, reject,isLoader=true) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: FEATURE_WARD_SET,
        isLoader,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _q2Integration = (endpoint,_payload, resolve, reject,isLoader=true) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: GET,
        isLoader,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _rawFetch = (endpoint,_payload, resolve, reject) => {
    
    const url = `${endpoint}`;
    
    const payload = {
        action: THEME_SET,
        isLoader:true,
        method: constdata.GET,
        apiConfig: {
            //headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _theme = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: THEME_SET,
        isLoader:true,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _user = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: USER_SET,
        isLoader:true,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _investment_account = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: INVESTMENTSLIST_SET,
        isLoader:true,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _get = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: GET,
        isLoader:true,
        method: constdata.GET,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _post = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: POST,
        isLoader:true,
        method: constdata.POST,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        data:_payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _put = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: POST,
        isLoader:true,
        method: constdata.PUT,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        data:_payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _patch = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: PATCH,
        isLoader:true,
        method: constdata.PATCH,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        data:_payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _delete = (endpoint,_payload, resolve, reject) => {
    
    const url = `${consturl.BASE_URL.API}/${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: DELETE,
        isLoader:true,
        method: constdata.DELETE,
        apiConfig: {
            headers: {...constdata.HEADERS,...authToken}
        },
        url: url,
        //data:_payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}