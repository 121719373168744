import {combineReducers} from 'redux';
import login from "./login";
import loader from "./loader";
import crud from "./crud";
import theme from "./theme";
import user from "./user";
import feature from "./feature";
import investmentaccount from "./investmentaccount";

const rootReducer = combineReducers({
    login, 
    loader,
    crud,
    theme,
    user,
    feature,
    investmentaccount
});

export default rootReducer;