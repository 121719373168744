import React from "react";

const EditProfileIcn = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <circle
        cx="24"
        cy="15.72"
        r="7.63"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.75"
      />
      <path
        d="M39.05,39.91c0-6.67-6.74-12.07-15.05-12.07s-15.05,5.4-15.05,12.07h30.1Z"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.75"
      />
    </svg>
  );
};

export default EditProfileIcn;
