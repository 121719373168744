import React, { useEffect, useState } from "react";
import { getToken, isLogin } from "../../Utils/token";

const Loader = (props) => {

    const type = isLogin() ? `-post` : '-post';
    const {loaderclass=""} = props;

    const [token, settoken] = useState();

    useEffect(() => {
        const t = getToken() || null;
        settoken(t);
    }, [])

    if (props.identifier === "0") {
        document.querySelectorAll('.loader-div').forEach(e => {
            e.classList.remove('show');
        })
    } else {
        document.querySelectorAll('.loader-div').forEach(e => {
            e.classList.add('show');
        })
    }

    return (
        <>

            <>
                {props.identifier === "0" &&
                    <div className={`loader-div hide ${loaderclass}`}>
                        <div className={`loader${type}`}>
                            <div className="ldio-7cxkyhjer4s">
                                <div></div>
                            </div>
                        </div>
                    </div>
                }
                {!token && props.identifier === "1" &&
                    <div className="loader-div-post hide">
                        <div className={`loader${type}`}>
                            <div className="ldio-7cxkyhjer4s">
                                <div></div>
                            </div>
                        </div>
                    </div>
                }
            </>

        </>
    );
}

export default Loader