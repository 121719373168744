import React from "react";

const Wallet = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
    >
      <path
        fill={iconColor}
        stroke={iconColor}
        d="M7.7,41H6.5c-1.3,0-2.4-1.1-2.4-2.4V11.7c0.5,0.3,1.2,0.5,1.8,0.5h34.4c1.3,0,2.4,1.1,2.4,2.4v6.2l-7.1,0
c-1,0-1.9,0.8-1.9,1.9l0,7.8c0,1,0.8,1.9,1.9,1.9h7.1v6.2c0,1.3-1.1,2.4-2.4,2.4H26.1L7.7,41z M26.1,42.6h14.2
c2.2,0,4.1-1.8,4.1-4.1v-6.2h1.7c1,0,1.9-0.8,1.9-1.9v-7.8c0-1-0.9-1.9-1.9-1.9l0,0h-1.7v-6.2c0-2.2-1.8-4.1-4.1-4.1H5.9
c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8h33.6c0.4,0,0.8-0.4,0.8-0.8S40,5.4,39.5,5.4H5.9C4,5.4,2.5,6.9,2.5,8.8v29.8
c0,2.2,1.8,4.1,4.1,4.1h1.2L26.1,42.6z M46.4,30.5c0,0.1-0.1,0.3-0.3,0.3H35.7c-0.1,0-0.3-0.1-0.3-0.3v-7.8c0-0.1,0.1-0.3,0.3-0.3
h10.4c0.1,0,0.3,0.1,0.3,0.3V30.5z M38.1,23.9c-0.4,0-0.8,0.4-0.8,0.8v3.8c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-3.8
C38.9,24.3,38.5,23.9,38.1,23.9z M39.5,8h-33C6.1,8,5.7,8.4,5.7,8.8c0,0.4,0.4,0.8,0.8,0.8h33c0.4,0,0.8-0.4,0.8-0.8
C40.4,8.4,40,8,39.5,8z"
      />
    </svg>
  );
};

export default Wallet;
