
export const getTokenCookie = () => {
  let allcookies = document.cookie.split(';');
  let token = false;
  for (let i = 0; i < allcookies.length; i++) {
    if (allcookies[i].split("=")[0].trim() === "access_token") {
      token = allcookies[i].split("=")[1];
    }
  }

  return token;
};

export const setToken = (access_token) => {
  let token = window.sessionStorage.setItem("access_token", access_token); 
  window.localStorage.setItem("access_token", access_token); 
  window.sessionStorage.removeItem("temp"); 
  return token;
};

export const setFirebaseToken = (access_token) => {
  let token = window.sessionStorage.setItem("fcm_token", access_token); 
  return token;
};

export const setTokenTemp = (access_token) => {
  let token = window.sessionStorage.setItem("access_token", access_token); 
  window.sessionStorage.setItem("temp","1"); 
  return token;
};

export const getToken = () => {
  let token = window.sessionStorage.getItem("access_token");
  return token;
};

export const getFCMToken = () => {
  let token = window.sessionStorage.getItem("fcm_token");
  return token;
};

export const isLogin = () => {
  let token = window.sessionStorage.getItem("access_token");
  let temp = window.sessionStorage.getItem("temp");
  if (token && !temp) {
    return true;
  }
  return false;
};

export const authToken = () => {
  const token = getToken();
  if(!token){
    return {};
  }
  return {
    "Authorization": `Bearer ${getToken()}`
  }
};

export const authTokenPwd = (token) => {
  if(!token){
    return {};
  }
  return {
    "Authorization": `PasswordReset ${token}`
  }
};

export const loginToken = (email, password) => {
  return {
    "Authorization": 'Basic ' + btoa(email + ':' + password)
  }
};

export const loginTokenAuth = (email, password) => {
  sessionStorage.setItem("email",email);
  return btoa(email + ':' + password)
};


